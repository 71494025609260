import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import React from 'react';
import Fade from 'react-reveal/Fade';

const GET_SECOND_QUERY = gql `
    query HomePage {
        homeSettings {
            homeSettings {
                hpsSecGrp {
                    fieldGroupName
                    hpsSecId
                    hpsSecTitle
                    hpsSecDesc
                    hpsSecContent
                    hpsSecShowHideBtnSect
                    hpsSecShowHideButtons
                    hpsSecImg {
                        altText
                        sourceUrl
                    }
                }
            }
        }
    }
`;

const VivaStudioSection = () => {
    const {loading, error, data} = useQuery(GET_SECOND_QUERY);
    const HomeSecondFound = Boolean(data?.homeSettings.homeSettings.hpsSecGrp);
    return (
        <>
            {loading ? (
            <p className="d-none">Loading…</p>
            ) : error ? (
                <p>Error: {error.message}</p>
            ) : !HomeSecondFound ? (
                <p>Section not be found.</p>
            ) : (
                <SecondSecLayout section={data.homeSettings.homeSettings.hpsSecGrp}/>
            )}
        </>
    )
}

const SecondSecLayout = (props) => {
    if(props.section.hpsSecShowHideBtnSect !== 'yes'){
        return (
            <section className="second-section vs-home-sec" id={props.section.hpsSecId}>
                <div className="inner">
                    <SectionHeader title={props.section.hpsSecTitle} desc={props.section.hpsSecDesc}/>
                    <SectionContent key={props} title={props.section.hpsSecTitle} desc={props.section.hpsSecDesc} image={props.section.hpsSecImg} content={props.section.hpsSecContent} button={props.section.hpsSecShowHideButtons}/>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

const SectionHeader = (props) => {
    if(props.title !== null && props.desc.length > 0){
        return (
            <div className="vs-sec-header mobile-to-tablet">
            {
                props.title ? (
                    <h1 className="flex flex-center sec-title txt-upper">{props.title}</h1>
                ) : null
            }
            {
                props.desc ? (
                    <span className="vs-sec-desc flex flex-center" 
                    dangerouslySetInnerHTML={{__html:props.desc}} />
                ) : null
            }
            </div>
        )
    }
}

// const SectionContent = ({ title, desc, image, content, button }) => {
//     let btnWrp = ( button === 'yes') ? <SectionBTN  mailto="mailto:info@vivagamestudio.com"/> : '';
//     return(
//         <>
//             <div className="vs-sec-content flex-row flex--children">
//                 <div className="w12">
//                 { image ? (
//                     <img
//                         src={image.sourceUrl}
//                         alt={image.altText} 
//                     />
//                 ) : null}
//                 </div>
//                 <div className="w12">
//                     <div className="vs-sec-header tablet-to-desktop">
//                     {
//                         title ? (
//                             <h1 className="flex flex-center sec-title txt-upper">{title}</h1>
//                         ) : null
                        
//                     }
//                     {
//                         desc ? (
//                             <span className="vs-sec-desc flex flex-center" 
//                             dangerouslySetInnerHTML={{__html:desc}} />
//                         ) : null
//                     }
//                     </div>
//                     {
//                         <div className="page-content" 
//                             dangerouslySetInnerHTML={{__html: content}}
//                         />
//                     }
//                     <div className="btnWrap">{btnWrp}</div>
//                 </div>
//             </div>
//         </>
//     )
// }

const SectionContent = ({ title, desc, image, content, button }) => {
    let btnWrp = button === 'yes' ? <SectionBTN mailto="mailto:info@vivagamestudio.com" /> : '';
  
    const coloredTitle = title.replace(
      /Viva games Studio/g,
      '<span style="color: #E31F2E;">Viva games Studio</span>'
    );
  
    // return (
    //   <>
    //     <div className="vs-sec-content flex-row flex--children">
    //       <div className="w12">
    //         {image ? (
    //           <img src={image.sourceUrl} alt={image.altText} />
    //         ) : null}
    //       </div>
    //       <div className="w12">
    //         <div className="vs-sec-header tablet-to-desktop">
    //           {title ? (
    //             <h1
    //               className="flex flex-center sec-title txt-upper"
    //               dangerouslySetInnerHTML={{ __html: coloredTitle }}
    //             />
    //           ) : null}
    //           {desc ? (
    //             <span
    //               className="vs-sec-desc flex flex-center"
    //               dangerouslySetInnerHTML={{ __html: desc }}
    //             />
    //           ) : null}
    //         </div>
    //         <div
    //           className="page-content"
    //           dangerouslySetInnerHTML={{ __html: content }}
    //         />
    //         <div className="btnWrap">{btnWrp}</div>
    //       </div>
    //     </div>
    //   </>
    // );
  
    return (
        <>
          <div className="vs-sec-content flex-row flex--children">
            <div className="w12">
              {image ? (
                <Fade left>
                <img src={image.sourceUrl} alt={image.altText} />
              </Fade>
              ) : null}
            </div>
            <div className="w12">
              <Fade right>
                <div className="vs-sec-header tablet-to-desktop">
                  {title ? (
                    <h1
                      className="flex flex-center sec-title txt-upper"
                      dangerouslySetInnerHTML={{ __html: coloredTitle }}
                    />
                  ) : null}
                  {desc ? (
                    <span
                      className="vs-sec-desc flex flex-center"
                      dangerouslySetInnerHTML={{ __html: desc }}
                    />
                  ) : null}
                </div>
                <div
                  className="page-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className="btnWrap">{btnWrp}</div>
              </Fade>
            </div>
          </div>
        </>
      );
};

const SectionBTN = (mailto) => {
    return (
        <>
            <Link 
                className="btn" 
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
                rel='nofollow'
                >Email Us</Link>
        </>
    )
}

export default VivaStudioSection;